import { AuthModal } from "#components";
import type { Product } from "~/types";

export function useProductFavorite(
    data: Pick<Product, "is_favorite" | "id" | "likes">,
    model = "",
) {
    const isFavorite = useState(
        `composables.useProductFavorite.${data.id + model}.isFavorite`,
        () => data.is_favorite,
    );
    const likes = useState(
        `composables.useProductFavorite.${data.id + model}.likes`,
        () => data.likes,
    );
    const allFavorite = useState("useAllProductsFavorite.count", () => 0);

    const user = useAuth();
    const modal = useModalStore();
    const prefix = `/api/v1/user/favorite${
        model.length > 1 ? "/" + model : ""
    }`;
    const property = model.includes("work") ? "master_work_id" : "id";

    return {
        isFavorite,
        likes,
        async swapFavorite() {
            if (!user.$state.loggedIn) {
                modal.open(AuthModal);
                return;
            }
            if (isFavorite.value) {
                const res = await useFetchWithCredentials(`${prefix}/remove`, {
                    method: "DELETE",
                    body: {
                        [property]: data.id,
                    },
                });
                if (res.status.value === "success") {
                    isFavorite.value = false;
                    allFavorite.value = Math.max(0, allFavorite.value - 1);
                    likes.value = res.data.value.likes;
                }
            } else {
                const res = await useFetchWithCredentials(`${prefix}/add`, {
                    method: "POST",
                    body: {
                        [property]: data.id,
                    },
                });
                if (res.status.value === "success") {
                    isFavorite.value = true;
                    allFavorite.value += 1;
                    likes.value = res.data.value.likes;
                }
            }
        },
    };
}
